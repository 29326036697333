import './Styles.css';
import ParagraphLogo from "./ParagraphLogo";
import logo from "../assets/people-logo.png";

export default function PeopleTextParagraph() {

    return (
        <>
            {/*<h1 style={mystyle}>*/}
            <div className="text-style">
                <ParagraphLogo logo={logo} />
                <p className="text-style">
                    Data shows that more gamers joined the community during the pandemic time. That means more people were introduced to the adventure of online games and social playing.
                    As that seems to keep engaging, the right platform needs to be prepared as those gamers are back to work and now need to create the perfect play mode while dedicating their  time to keep doing what they fell in love with, online gaming.
                </p>
            </div>
        </>

    )
}