import './Styles.css';
import ParagraphLogo from "./ParagraphLogo";
import logo from "../assets/product-logo.png";

export default function ProductTextParagraph() {

    return (
        <>
            {/*<h1 style={mystyle}>*/}
            <div className="text-style">
                <ParagraphLogo logo={logo} />
                <div className="text-style">
                    Pistol launcher is the best launcher for any mobile gamer. Our team worked on every detail to enhance your gaming experience to the highest level possible.
                </div>
            </div >
        </>
    )
}