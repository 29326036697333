import Header from "../components/Header";
import TopLogoImg from "../components/TopLogoImg";
import PeopleTextParagraph from "../components/PeopleTextParagraph";
import WorkTextParagraph from "../components/WorkTextParagraph";
import ProductTextParagraph from "../components/ProductTextParagraph";

const buttonClickHandler = () => {
    window.location.href = '/landing'
    // window.open("/landing", "_blank")
}

export default function MainPage() {
    return (
        <>
            <TopLogoImg />
            <Header text="Pistol" />
            <div className="wrapper">
                <p className="main-text-style">
                    Pistol was founded in 2022 where gaming is still one of the most popular activities among young and adults. We believe in improving our users' experience and provide the right conditions and tools to make your gaming world better. Our team is combined with young and adult programmers and developers who adore online games and are part of its community.
                </p>
                <button className="button-style" onClick={buttonClickHandler}>
                    Our Launcher
                </button>
            </div>

            <div className="wrapper">
                <PeopleTextParagraph />
                <ProductTextParagraph />
            </div>

        </>
    )
}

