import LeftText from "../components/LeftText";
import RightText from "../components/RightText";
import logo from "../assets/center-logo-pistol.svg";
import screenShot from "../assets/screen-shot.png";


export default function LandingPage() {
    const textStyle = {
        marginTop: "25px",
        marginBottom: "70px",
        color: "#b68545",
        fontSize: "25px",
        width: "40%",
        height: "53%",
    };
    const logoStyle = {
        width: '50%'
    };
    const divStyle = {
        width: '100%',
    };

    const buttonClickHandler = () => {
        window.location.href = 'https://youtube.com/'
        // window.open("/landing", "_blank")
    }

    return (
        <>
            <h3 style={textStyle}>Pistol Launcher</h3>
            <div style={divStyle}>

                <div className="left-right-text-style">
                    <p className="left-text-style">
                        {/* <h3>The Pistol Launcher</h3> */}
                        Pistol is a mobile  gaming launcher (iOS and Android). Every detail was designed to enhance your gaming experience to bring it to the highest level possible.
                    </p>
                    <img style={logoStyle} src={logo} alt="logo" />
                    <button className="button-style" onClick={buttonClickHandler} >
                        Download
                    </button>
                </div>
            </div>


            <RightText text="High performance thanks to the mobile power proper distribution which also credit you with a power save mode to keep you in the game as long as possible." logo={screenShot} />
            <LeftText text="Comfort vision-night mode vision light sensitivity will turn on once it's darkening." logo={screenShot} />
            <RightText text="User Friendly-this launcher is super cool and easy to use." logo={screenShot} />
            <LeftText text="No disturb mode- it is obvious that you need to be focused to win and perform high scores. You can set up your call and messaging receiving option to get the perfect conditions for you." logo={screenShot} />
            <RightText text="Data summery center- where you view your games dates and scores." logo={screenShot} />
            <LeftText text="Pistol messenger-  see your friends online status, invite friends for games and chat with them live using a built in discord." logo={screenShot} />
            <RightText text="Play & share- Record and Share screen live." logo={screenShot} />
            <div className="bottom-text-style">
                <a>Time is up! </a>
                <a href="https://youtube.com/">
                    Click and Play
                </a>
            </div>
        </>
    )
}

