import logo from "../assets/center-logo-pistol.svg";
import './Styles.css';

export default function CenterLogoImg() {
    const logoStyle = {
        width: '80%',
        marginBottom: '30px'
    };

    return (
        <div>
            <img style={logoStyle} src={logo} alt="logo" />
        </div>
    )
}