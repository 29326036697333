import TopLogoImg from "../components/TopLogoImg";

export default function Page404() {
    return (
        <>
            <TopLogoImg />
            <div className="error-style">
                <a>Ohh no...</a>
                <a>Pistol is currently working on some improvement</a>
                <a>Please visit us again later.</a>
                <h1>404</h1>
            </div>
        </>
    )
}

