import './Styles.css';
import CenterLogoImg from "../components/CenterLogoImg";

export default function Header({ text }) {


    return (
        <>

            <div className="Header-style">

                <h1>
                    {text}
                </h1 >
                <CenterLogoImg />
            </div>
        </>

    )
}