import logo from "../assets/pistol-logo.svg"

export default function TopLogoImg() {
    const logoStyle = {
        width: '10%'
    };


    return (
        <>
            <div className="logo-style">
                <img style={logoStyle} src={logo} alt="logo" />
            </div>
        </>
    )
}