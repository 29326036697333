import React from "react";
import {
  BrowserRouter,
  Switch,
  Route,
} from "react-router-dom";

import Page404 from '../page/Page404'
import MainPage from '../page/MainPage'
import LandingPage from '../page/LandingPage'

export default function AppRoute() {
  return (
    <BrowserRouter basename={'/'} >
      <Switch>
        <Route exact path={`${process.env.PUBLIC_URL}/`} component={MainPage} />
        <Route path={`${process.env.PUBLIC_URL}/landing`} component={LandingPage} />
        <Route path={`${process.env.PUBLIC_URL}/*`} component={Page404} />
      </Switch>
    </BrowserRouter>

  );
}