import './Styles.css';

export default function ParagraphLogo({ logo }) {
    const logoStyle = {
        width: '80px',
        height: '80px',
    };

    return (
        <div>
            <img style={logoStyle} src={logo} alt="logo" />
        </div>
    )
}