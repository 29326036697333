import './Styles.css';

export default function RightText(props) {
    const logoStyle = {
        width: '25%'
    };

    return (
        <>
            <div className="left-right-text-style">
                <div className="left-right-wrapper">
                    <img style={logoStyle} src={props.logo} alt="logo" />
                    <p className="right-text-style">
                        {props.text}
                    </p>
                </div>
            </div>
        </>

    )
}